@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
html,
body,
h1,
h2,
h3,
h4,
p,
div,
span {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
}
.App {
  text-align: center;
}
.App-title {
  font-size: 1.5em;
}
.App-intro {
  font-size: large;
}
